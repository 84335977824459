import { FC, PropsWithChildren } from 'react';
import layoutData from './../../../layout-data.preval';
import HeaderBanner from '../ui/banners/header-banner/HeaderBanner';
import Meta from '../seo/Meta';
import { IMeta } from '../../interfaces/meta.interface';
import { IWidgets } from '@/interfaces/widgets.interface';
import localFont from 'next/font/local';
import SidebarLeft from './sidebar-left/SidebarLeft';
import SidebarRight from './sidebar-right/SidebarRight';
import Header from './header/Header';
import Footer from './footer/Footer';
import StickyBanner from '../ui/banners/sticky-banner/StickyBanner';
import BottomBanner from '../ui/banners/bottom-banner/BottomBanner';
import styles from './Layout.module.scss';

const fontArial = localFont({
  src: [
    {
      path: './../../fonts/arial.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './../../fonts/arial-bold.woff',
      weight: '700',
      style: 'bold',
    },
  ],
});

interface ILayout extends IMeta {
  isSmallPage?: boolean;
  isNoSideBars?: boolean;
  breadcrumbs?: React.ReactNode;
}

const Layout: FC<PropsWithChildren<ILayout>> = ({ children, title, description, pageHref, canonicalHref, cover, isSmallPage, isNoSideBars, breadcrumbs, robotsNoFollow, widgetsData }) => {
  const prefetchedLayoutData = layoutData as IWidgets;

  return (
    <Meta title={title} description={description} pageHref={pageHref} cover={cover} canonicalHref={canonicalHref} robotsNoFollow={robotsNoFollow}>
      <HeaderBanner />
      <main className={styles.mainContainer} style={fontArial.style}>
        <Header />

        <div className={styles.mainContainer__content}>
          {isNoSideBars ? (
            <div className={`${styles.mainContainer__wrap} ${styles.mainContainer__wrap_fullWidth}`}>
              <div className="contentHeader">
                <div className="contentHeader__item">{breadcrumbs}</div>
              </div>
              <div className="content">{children}</div>
            </div>
          ) : (
            <>
              <div className={styles.mainContainer__sidebar}>
                <SidebarLeft isSmallPage={isSmallPage} slider={prefetchedLayoutData.slider} popularBook={prefetchedLayoutData.popular_book} popularGenres={prefetchedLayoutData.popular_genres} />
              </div>
              <div className={isSmallPage ? `${styles.mainContainer__wrap} ${styles.mainContainer__wrap_fullHeight}` : `${styles.mainContainer__wrap}`}>
                {breadcrumbs ? (
                  <>
                    <div className="contentHeader">
                      <div className="contentHeader__item">{breadcrumbs}</div>
                    </div>
                    <div className="content">{children}</div>
                  </>
                ) : (
                  children
                )}
              </div>
              <div className={`${styles.mainContainer__sidebar} ${styles.mainContainer__sidebar_right}`}>
                <SidebarRight isSmallPage={isSmallPage} today={prefetchedLayoutData.today} lastReviews={prefetchedLayoutData.last_reviews} popularSearch={prefetchedLayoutData.popular_search} trendBook={prefetchedLayoutData.trend_book} />
              </div>
            </>
          )}
        </div>
        <Footer />
        <BottomBanner />
      </main>

      <StickyBanner />
    </Meta>
  );
};

export default Layout;
